import { COMMUNICATION_TYPE } from '../../../misc/commons'
import noticeWebform from '../sales-rep-notice-webform'
import mockUpWebform from '../../share/sales-notice-mockup'
import _ from 'lodash'
export default {
    components: {
        noticeWebform
    },
    data() {
        return {
            organizationId: null,
            providerCommunicationId: null,
            communicationId: null,
            communicationType: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            loadMockWebform: true
        }
    },
    created() {
        if (this.$route && this.$route.query.o && this.$route.query.r && this.$route.query.pr) {
            this.loadMockWebform= false
            this.organizationId = Number(this.$route.query.o)
            this.communicationId = this.$route.query.r
            this.providerCommunicationId = Number(this.$route.query.pr)
        }
    },
}
